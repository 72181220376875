<script setup lang="ts">
import type { PdpEcommerceBlockFitFinderProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockFitFinder.props'
import type {
  Category,
  SizeDetails,
} from '@design-system/components/Pdp/PdpFitFinder.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const props = defineProps<PdpEcommerceBlockFitFinderProps>()
const emit = defineEmits<{
  onFitFinderClick: []
}>()

const { openDialog, closeDialog } = useDialog(
  SIDEBAR_IDS.pdpEcommerceBlockFitFinder
)

const handlePrimaryClick = () => {
  closeDialog()
}

const { getSizesByCode, countries, macroCategories } =
  await useSizeRetrieveData()

const { data: sizeData } = await getSizesByCode(props.sizeCodeId)

const productCategory = computed(() => {
  if (!props.sizeCodeId) return null
  if (!macroCategories) return null

  for (const category of macroCategories) {
    const subCategories = category?.subCategories
    if (!subCategories) continue
    for (const subCategory of subCategories) {
      const sizes = subCategory?.sizes
      if (!sizes) continue
      for (const size of sizes) {
        if (size === props.sizeCodeId) {
          return subCategory
        }
      }
    }
  }
  return null
})

const emitSizebayProductViewEvent = () => {
  const productViewEvent = new CustomEvent('product_view', {
    detail: {
      productId: props.productId,
      productTitle: props.productTitle,
    },
  })
  window.dispatchEvent(productViewEvent)
}
const handleFitFinderClick = () => {
  openDialog()
  emit('onFitFinderClick')
  emitSizebayProductViewEvent()
}
</script>
<template>
  <AsideButton
    class="border-t-0"
    data-testid="fit-finder"
    :aria-label="$ts('accessibility.openFitFinder')"
    @click="handleFitFinderClick"
  >
    <p class="text-medium-6">
      {{ $ts('pdp.fitFinder.label') }}
    </p>
  </AsideButton>

  <OrganismsSidebarSlide
    v-if="!hideSidebar"
    :id="SIDEBAR_IDS.pdpEcommerceBlockFitFinder"
    :header-props="{
      titleText: $ts('pdp.fitFinder.label'),
    }"
    @primary-click="handlePrimaryClick"
  >
    <template #body>
      <PdpFitFinder
        :category="productCategory as Category"
        :countries="countries"
        :sizes-details="sizeData as unknown as SizeDetails[]"
        :side-slide-description="props.sideSlideDescription"
      />
    </template>
  </OrganismsSidebarSlide>
</template>
