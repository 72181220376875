import { z } from 'zod'
import { itemSchema } from '.'

export const viewItemSchema = z.object({
  event: z.literal('view_item'),
  eventID: z.literal('005'),
  ecommerce: z.object({
    view_type: z.string(),
    currency: z.string(),
    value: z.number(),
    items: itemSchema,
  }),
})
