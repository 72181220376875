<script lang="ts" setup>
import type {
  PdpEcommerceBlockProductDetailsProps,
  SkuSize,
} from './PdpEcommerceBlockProductDetails.props'
import type { ColorOption } from '@design-system/components/Pdp/PdpColorSelector.props'
import type { Size, extSize } from '../../PdpSizeSelector.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { addToCart } = useAddToCart()

const props = withDefaults(
  defineProps<PdpEcommerceBlockProductDetailsProps>(),
  {
    selectedExtSku: '',
    thisProductDims: '',
  }
)

const emit = defineEmits<{
  'update:selectedSizeIndex': [selectedSizeIndex: number | null]
  'update:selectedCountry': [selectedCountry: string]
  'update:selectedExtSku': [selectedExtSku: string]
  'similar-products': []
  openSidebar: [sidebarId: string]
  addToCart: []
}>()

const { openDialog } = useDialog()
const { getCanonicalUrl, storeLocator, homeBrandPath } = useRouteHelper()
const { marketDefaultShippingMethod } = useShippingMethods()
const { dispatchFitAnalyticsEvent } = useGANavigationEvents()
const { dispatchActionReserveInStoreEvent } = useGAActionEvents()

const lastInStock = computed(() => {
  if (!props.sizes[props.selectedCountry]) return false

  // CL check
  const sizes = props.sizes[props.selectedCountry]
  const disabledCount = sizes.filter((item: Size) => item.disabled).length
  const lastInStockCount = sizes.filter(
    (item: Size) => item.lastInStock && item.disabled === false
  ).length

  // algolia check
  const skuSizes = props.skuSizes
  const disabledCountAlgolia = skuSizes.filter(
    (item: SkuSize) => item.avEcom === 0 && item.avRet === 0
  ).length
  const lastInStockCountAlgolia = skuSizes.filter(
    (item: SkuSize) => item.avEcom === 1 || item.avRet === 1
  ).length

  // to be last in stock all sizes but one have to be disabled (outOfStock) and only one size has to be lastInStock
  return (
    (disabledCount === sizes.length - 1 && lastInStockCount === 1) ||
    (disabledCountAlgolia === skuSizes.length - 1 &&
      lastInStockCountAlgolia === 1)
  )
})

const { currentWebsite } = useWebsite()

const onlyStoreMobile = useState<boolean | undefined>('onlyStoreMobile')

const onlyStore = computed(() => {
  const res =
    props.skuSizes?.every(item => item.avEcom === 0) &&
    props.skuSizes?.some(item => item.avRet && item.avRet > 0)
  onlyStoreMobile.value = res
  return res
})

const outOfStock = computed(
  () =>
    props.skuSizes?.every(item => item.avEcom === 0 && item.avRet === 0) &&
    props.sizes[props.selectedCountry]?.every(item => item.disabled)
)

const oneSize = computed(() => {
  if (!props.sizes[`${props.selectedCountry}`]) {
    return false
  }
  const firstSize = props.sizes?.[`${props.selectedCountry}`]?.[0]?.['sizeCode']
  const res =
    (firstSize === 'TU' || firstSize === 'ONESIZE') &&
    (!props.extSizes || props.extSizes.length === 0)

  return res
})

const { getDims } = await usePdpExtSizesUtils()

const productRoute = props.productRoute ?? ''

const dims = computed(() => {
  return getDims(
    props.selectedExtSku,
    props.extSizes ?? [],
    props.thisProductDims,
    productRoute,
    props.skuSizes,
    oneSize && !extSizesCheck.value
  )
})

const selectedSizeState = useState<extSize>('selectedSizeState')

const selectedSize = computed(() => {
  if (!props.sizes[props.selectedCountry]) return 0

  // one size
  if (oneSize.value && !props.extSizes) return 0
  // ext sizes
  if (props.extSizes && props.extSizes.length > 0) {
    return selectedSizeState?.value?.value ?? 0
  }
  // default
  return props.selectedSizeIndex
})

const outOfStockExtSizes = computed(() => {
  if (!props.extSizes) return false
  const totalAvEcom = props.extSizes.reduce(
    (acc, product) => acc + (product.avEcom ?? 0),
    0
  )
  const totalAvRet = props.extSizes.reduce(
    (acc, product) => acc + (product.avRet ?? 0),
    0
  )
  const CLAllExtOos = props.extSizes.every(
    (item: extSize) => item.outOfStock === true
  )
  if (
    (totalAvEcom + totalAvRet === 0 && props.extSizes.length > 0) ||
    CLAllExtOos
  ) {
    return true
  }
  return false
})

const _addToCart = async () => {
  await addToCart()
  emit('addToCart')
}

const clickAddToCart = async (id: string) => {
  if (
    (props.selectedSizeIndex !== null && props.selectedSizeIndex >= 0) ||
    extSizesCheck.value
  ) {
    await _addToCart()
    if (!oneSize.value) emit('update:selectedSizeIndex', null)
  } else {
    openDialogIfNotDisabled(id)
  }
}

const openDialogIfNotDisabled = (id: string) => {
  if (
    (props.extSizes?.length === 0 && !outOfStock.value) ||
    (props.extSizes && props.extSizes.length > 0 && !outOfStockExtSizes.value)
  )
    openDialog(id)
}
const selectColor = (selectedProductColor: ColorOption) => {
  const path = getCanonicalUrl(selectedProductColor.path)
  navigateTo(path)
}

const {
  title: infoTitle,
  hideInCountry,
  hideInCategory,
} = await useInfoReturn(
  `${currentWebsite.value}-info-return`,
  props.productCategoriesSlugs
)

const isExtSizeOrOneSizeFitFinder = computed(
  () => !!extSizesCheck.value || !!oneSize.value
)

const extSizesCheck = computed(() => {
  const res = props.extSizes && props.extSizes.length > 0
  return res
})
const selectedExtOutOfStockMobile = useState<boolean | undefined>(
  'selectedExtOutOfStockMobile'
)

const selectedExtOutOfStock = computed(() => {
  if (props.extSizes && props.extSizes.length > 0) {
    const myObj = props.extSizes.find(
      (obj: extSize) =>
        obj.SKU === props.selectedExtSku && obj.Dims === dims.value
    )
    selectedExtOutOfStockMobile.value = myObj?.outOfStock ?? false
    return myObj?.outOfStock
  }
})

const selectedDefaultOrOneSizeOutOfStockCl = computed(() => {
  if (extSizesCheck.value) return false
  if (oneSize.value) {
    return (
      props.sizes[`${props.selectedCountry}`][0]['disabled'] ||
      (props.skuSizes[0].avRet === 0 && props.skuSizes[0].avEcom === 0)
    )
  }
  if (
    props.sizes[`${props.selectedCountry}`].every(
      (item: Size) => item.disabled === true
    ) ||
    props.skuSizes?.every(
      (item: SkuSize) => item.avEcom === 0 && item.avRet === 0
    )
  ) {
    return true
  } else {
    return false
  }
})

const disabledMobileAddToCart = useState<boolean | undefined>(
  'disabledMobileAddToCart'
)

const allExtSizesOosOrDefaultSizesOos = computed(() => {
  const res =
    (selectedDefaultOrOneSizeOutOfStockCl.value && !extSizesCheck.value) ||
    (outOfStockExtSizes.value && extSizesCheck.value)
  disabledMobileAddToCart.value = res ?? false
  return res
})

const handleReserveClick = () => {
  emit('openSidebar', SIDEBAR_IDS.wrapperReserveInStore)
  dispatchActionReserveInStoreEvent(
    props.title,
    selectedSize.value
      ? props.skuSizes[Number(props?.selectedSizeIndex)].label!
      : '',
    props.selectedColor,
    props.selectedExtSku
  )
}

const isCasa = computed(() => {
  return props.brand === 'ARMANI CASA'
})
const isInfoCommerceProduct = computed(() => {
  return isCasa.value && props.infoCommerce
})

const baaUrl = computed(() => {
  return `/armani-casa/experience/book-an-appointment/`
})

const emitActionAside = () => {
  if (isCasa.value) {
    const path = getCanonicalUrl(storeLocator)
    navigateTo(path, {
      external: true,
    })
  } else {
    emit('similar-products')
  }
}
</script>

<template>
  <div
    class="xl:pt-7xl lg:pt-3xl pb-lg flex w-full flex-col justify-between lg:h-full"
  >
    <div
      class="title-price-block mt-sm flex flex-col gap-8 lg:mt-0 xl:gap-10"
      :class="{
        'gap-xl lg:gap-lg xl:gap-4xl': !hideDetails,
      }"
    >
      <PdpEcommerceBlockProductName
        :class="{ 'hidden lg:block': !hideDetails }"
        :title="title"
        :price="price"
        :old-price="oldPrice"
        :discount="discount"
        :tags="tags"
        :is-shoppable="!!isShoppable && !isInfoCommerceProduct"
        :is-heading="isHeading"
      />
      <PdpEcommerceBlockColorSelector
        v-if="!onlyStore && !isInfoCommerceProduct"
        :sidebar-id="`${SIDEBAR_IDS.pdpColorSelectorProductDetails}-${productId}`"
        :sku-code="productId"
        :hide-sidebar-component="hideDetails"
        :options="colorOptions"
        :selected-color="selectedColor"
        :ext-colors="extColors"
        :color-hex="colorHex"
        @update:selected-option="selectColor"
      />
      <div
        v-if="
          allExtSizesOosOrDefaultSizesOos ||
          selectedExtOutOfStock ||
          isRetailExclusive ||
          isInfoCommerceProduct
        "
      >
        <AtomsBadgeProductCart
          :key="'pdp-badge-product-card-only-store'"
          :text="$ts('pdp.productDetails.informationSection.onlyStore')"
        />
      </div>
      <div
        v-if="
          isShoppable &&
          !onlyStore &&
          !allExtSizesOosOrDefaultSizesOos &&
          !isInfoCommerceProduct
        "
        class="gap-md flex flex-col"
      >
        <div class="flex" :class="{ 'h-[100px]': oneSize && !dims }">
          <div class="flex-1">
            <PdpEcommerceBlockSizeSelector
              :hide-sidebar-component="hideDetails"
              :selected-size-index="selectedSize"
              :selected-country="selectedCountry"
              :sizes="sizes"
              :sku-sizes="skuSizes"
              :ext-sizes="extSizes"
              :selected-ext-sku="selectedExtSku"
              :side-slide-description="sideSlideDescription"
              :is-one-size="oneSize"
              :dims="dims"
              :out-of-stock="outOfStockExtSizes"
              :disabled="outOfStock && oneSize"
              :selected-ext-out-of-stock="selectedExtOutOfStock"
              :selected-default-or-one-size-out-of-stock-cl="
                selectedDefaultOrOneSizeOutOfStockCl
              "
              @send-add-to-cart="_addToCart"
              @update:selected-size-index="
                emit('update:selectedSizeIndex', $event)
              "
              @update:selected-country="emit('update:selectedCountry', $event)"
              @update:selected-ext-sku="emit('update:selectedExtSku', $event)"
              @similar-products="$emit('similar-products')"
            />

            <AsideButton
              v-if="isExtSizeOrOneSizeFitFinder && dims"
              class="border-t-0"
              :class="{
                'cursor-not-allowed':
                  outOfStockExtSizes || (oneSize && outOfStock),
              }"
              :hide-arrow="oneSize"
              :aria-label="$ts('accessibility.openFitFinder')"
              @click="dispatchFitAnalyticsEvent"
            >
              <p
                v-if="selectedSizeState?.Dims"
                class="text-medium-6 font-light"
              >
                {{ selectedSizeState?.Dims }}
              </p>
            </AsideButton>
            <PdpEcommerceBlockFitFinder
              v-if="!isExtSizeOrOneSizeFitFinder"
              :hide-sidebar="hideDetails"
              :size-code-id="sizeGridCode"
              :product-title="title"
              :product-id="productId"
              :side-slide-description="sideSlideDescription"
              @on-fit-finder-click="dispatchFitAnalyticsEvent"
            />
          </div>
          <div class="hidden lg:block">
            <PdpEcommerceBlockProductAddToCartButton
              :is-loading="addToCartProps.isLoading"
              :error="addToCartProps.error"
              :selected-country="selectedCountry"
              :is-one-size="oneSize"
              :disabled="outOfStock && oneSize"
              :out-of-stock="outOfStockExtSizes && extSizesCheck"
              :selected-ext-out-of-stock="selectedExtOutOfStock"
              :selected-default-or-one-size-out-of-stock-cl="
                selectedDefaultOrOneSizeOutOfStockCl
              "
              @send-add-to-cart="
                clickAddToCart(SIDEBAR_IDS.pdpEcommerceBlockSizeSelector)
              "
              @update:selected-size-index="
                emit('update:selectedSizeIndex', $event)
              "
              @update:selected-country="emit('update:selectedCountry', $event)"
            />
          </div>
        </div>
        <AtomsBadgeProductCart
          v-if="lastInStock && !hideDetails"
          :text="$ts('pdp.productDetails.informationSection.lastInStock')"
        />
        <div v-if="!hideDetails" class="gap-xs flex flex-col">
          <PaypalWrapper v-if="priceCents" :amount="priceCents" />
          <KlarnaPlacementWrapper
            v-if="priceCents"
            :placement="{
              key: 'credit-promotion-auto-size',
              purchaseAmount: priceCents,
            }"
          />
          <AtomsMessageInfo
            icon="shipping"
            :text="marketDefaultShippingMethod?.pdpDescription ?? ''"
          >
            <template #dynamicContent>
              <button
                :aria-label="$ts('accessibility.openShippingSidebar')"
                @click="emit('openSidebar', SIDEBAR_IDS.pdpInfoShippingItem)"
              >
                <DefaultIconsInfo class="h-4 w-4" aria-hidden="true" />
              </button>
            </template>
          </AtomsMessageInfo>
          <AtomsMessageInfo
            v-if="!hideInCountry && !hideInCategory"
            icon="return"
            :text="infoTitle"
          >
            <template #dynamicContent>
              <button
                :aria-label="$ts('accessibility.openReturnInfoSidebar')"
                @click="emit('openSidebar', SIDEBAR_IDS.pdpInfoReturnItem)"
              >
                <DefaultIconsInfo class="h-4 w-4" aria-hidden="true" />
              </button>
            </template>
          </AtomsMessageInfo>
        </div>
      </div>
      <div
        v-if="
          (!onlyStore &&
            !allExtSizesOosOrDefaultSizesOos &&
            !hideDetails &&
            !isInfoCommerceProduct) ||
          isRetailExclusive
        "
      >
        <AtomsCta
          anatomy="link"
          class="text-link-7"
          aria-haspopup="dialog"
          @click="handleReserveClick"
        >
          <template #label>
            {{ $ts('pdp.productDetails.informationSection.reserve') }}
          </template>
        </AtomsCta>
      </div>
      <div
        v-if="onlyStore && !hideDetails && !isInfoCommerceProduct"
        class="max-md:hidden"
      >
        <AsideButton
          data-testid="size-selector"
          :centered-btn="onlyStore"
          @click="emit('openSidebar', SIDEBAR_IDS.wrapperReserveInStore)"
        >
          <span class="text-light-6">
            {{ $ts('pdp.productDetails.informationSection.reserve') }}
          </span>
        </AsideButton>
      </div>
      <div
        v-if="
          (allExtSizesOosOrDefaultSizesOos || isInfoCommerceProduct) &&
          !hideDetails
        "
      >
        <div v-if="isInfoCommerceProduct">
          <div class="flex flex-col items-start gap-6 pb-6">
            <div class="text-light-6 line-clamp-3">
              <!--
              Commentato temporaneamente per non mostrare la sezione
              <p>
                {{
                  $ts(
                    'pdp.productDetails.informationSection.armaniCasa.findDetails'
                  )
                }}
                <MoleculesLinkWrapper
                  :to="props.productPdf !== '' ? props.productPdf : '#'"
                  target="_blank"
                  rel="noopener noreferrer"
                  anatomy="link"
                >
                  <template #label>
                    {{
                      $ts(
                        'pdp.productDetails.informationSection.armaniCasa.dataSheet'
                      )
                    }}
                  </template>
                </MoleculesLinkWrapper>
              </p> -->
            </div>
            <div class="text-light-6 line-clamp-3">
              <p>
                {{
                  $ts(
                    'pdp.productDetails.informationSection.armaniCasa.pleaseVisit'
                  )
                }}
                <MoleculesLinkWrapper
                  :to="storeLocator"
                  anatomy="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <template #label>
                    {{
                      $ts(
                        'pdp.productDetails.informationSection.armaniCasa.storeLocator'
                      )
                    }}
                  </template>
                </MoleculesLinkWrapper>
                {{
                  $ts(
                    'pdp.productDetails.informationSection.armaniCasa.storeLocatorSentance'
                  )
                }}
              </p>
            </div>
            <div class="text-light-6 line-clamp-3">
              <p>
                {{
                  $ts(
                    'pdp.productDetails.informationSection.armaniCasa.bookYour'
                  )
                }}
                <MoleculesLinkWrapper
                  :to="baaUrl"
                  anatomy="link"
                  :title="'in-store appointment'"
                >
                  <template #label>
                    {{
                      $ts(
                        'pdp.productDetails.informationSection.armaniCasa.appointment'
                      )
                    }}
                  </template>
                </MoleculesLinkWrapper>
                {{
                  $ts(
                    'pdp.productDetails.informationSection.armaniCasa.appointmentSentence'
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <AsideButton
          :centered-btn="
            outOfStock ||
            outOfStockExtSizes ||
            selectedDefaultOrOneSizeOutOfStockCl
          "
          data-testid="button-out-of-stock"
          @click="emitActionAside"
        >
          <span class="text-light-6">
            {{
              isCasa
                ? $ts('pdp.sizeSelector.storeLocatorCta')
                : $ts('pdp.sizeSelector.emptyStock')
            }}
          </span>
        </AsideButton>
      </div>
    </div>
  </div>
</template>
