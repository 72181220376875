<script setup lang="ts">
import type { PdpEcommerceBlockProductNameProps } from './PdpEcommerceBlockProductName.props'
const props = defineProps<PdpEcommerceBlockProductNameProps>()
const MAX_LABEL_NUMBER = 3

const items = computed(() => {
  return props.tags?.slice(0, MAX_LABEL_NUMBER) ?? []
})

const { formatPriceByLocale } = useFormatPrice()
const { currentCurrency } = useCurrency()
</script>

<template>
  <div class="gap-xxs lg:gap-sm text-primitives-black flex flex-col">
    <div
      v-if="items.length > 0"
      class="gap-xs mt-sm mb-sm order-3 flex lg:order-1 lg:mt-0"
    >
      <AtomsBadgeProductCart
        v-for="(tag, index) in items"
        :key="'pdp-badge-product-card-' + index"
        :text="tag"
      />
    </div>

    <component
      :is="isHeading ? 'h1' : 'span'"
      class="text-book-5 py-primitives-2 order-1 lg:order-2"
    >
      {{ title.toUpperCase() }}
    </component>

    <div
      v-if="isShoppable"
      class="text-light-6 py-primitives-2 order-2 lg:order-3"
    >
      <div class="text-light-6 flex gap-x-2">
        <span
          v-if="oldPrice"
          class="text-primitives-grey-200 text-light-strike-6 line-through"
        >
          {{ formatPriceByLocale(currentCurrency, oldPrice) }}
        </span>
        <span v-if="price">{{
          formatPriceByLocale(currentCurrency, price)
        }}</span>
        <span v-if="discount" class="text-medium-6">{{ discount }}</span>
      </div>
    </div>
  </div>
</template>
