<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { PdpEcommerceBlockReserveInStoreStepZeroProps } from './PdpEcommerceBlockReserveInStoreStepZero.props'

const props = defineProps<PdpEcommerceBlockReserveInStoreStepZeroProps>()
const emit = defineEmits<{
  'update:selectedCountry': [selectedCountry: string]
}>()

const { getCanonicalUrl } = useRouteHelper()
const selectedSizeIndex = defineModel<number>()

const selectColor = (selectedProductColor: { color: string; sku: string }) => {
  const path = getCanonicalUrl(`${selectedProductColor.sku}`)
  navigateTo(path)
}
</script>

<template>
  <div class="flex flex-col">
    <PdpEcommerceBlockColorSelector
      :sidebar-id="`${SIDEBAR_IDS.pdpColorSelectorReserveInStore}-${props.productId}`"
      :sku-code="productId"
      :options="props.colorOptions"
      :selected-color="props.selectedColor"
      @update:selected-color="selectColor"
    />
    <p
      v-if="isNotSelectedSize"
      class="text-book-6 text-primitives-red"
      role="alert"
      aria-live="assertive"
    >
      {{ $ts('pdp.sizeSelector.selectSizeError') }}
    </p>
    <div class="mb-3 mt-10 flex w-full items-center justify-between">
      <p class="uppercase">{{ $ts('pdp.sizeSelector.selectSize') }}</p>
      <!-- REMOVED FOR LIVE -->
      <!-- <AtomsCta anatomy="link" class="text-link-7">
        <template #label>
          {{ $ts('pdp.sizeSelector.fitFinder') }}
        </template>
      </AtomsCta> -->
    </div>
    <PdpSizeSelector
      :selected-size-index="selectedSizeIndex ?? null"
      :sizes="sizes"
      :selected-country="selectedCountry"
      :is-reserve-in-store="true"
      @update:selected-size-index="
        newVal => (selectedSizeIndex = newVal ?? undefined)
      "
      @update:selected-country="emit('update:selectedCountry', $event)"
    />
  </div>
</template>
