<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { PdpEcommerceBlockProductInfoProps } from './PdpEcommerceBlockProductInfo.props'

const props = defineProps<PdpEcommerceBlockProductInfoProps>()
const emit = defineEmits<{
  'update-wishlist': []
  'complete-the-look': []
}>()

const url = useRequestURL()
const fullURL = url.href

const { wishlistProductCodes } = await useWishlist()
const { openDialog } = useDialog()
const addedToWishlist = ref()

onMounted(() => {
  addedToWishlist.value = wishlistProductCodes.value[props.productCode]
})

watch(wishlistProductCodes, () => {
  addedToWishlist.value = wishlistProductCodes.value[props.productCode]
})
</script>

<template>
  <div class="lg:pb-lg lg:pt-3xl xl:pt-7xl flex flex-col">
    <div
      class="carousel-container-overflowed hidden shrink-0 overflow-hidden lg:-mr-12 lg:mb-6 lg:block lg:pr-12 xl:-mr-16 xl:mb-16 xl:pr-16"
    >
      <!-- Small gallery -->
      <PdpEcommerceBlockGalleryThumbnails :gallery="gallery" />
    </div>

    <div
      class="py-md flex h-full flex-col justify-between lg:gap-6 lg:py-0 xl:gap-16"
    >
      <div class="lg:gap-sm gap-lg xl:gap-lg flex flex-col">
        <div class="flex flex-col">
          <!-- Info & Details Section -->
          <PdpEcommerceBlockProgressiveLabel
            :label="$ts('pdp.productDetails.infoAndDetails')"
            @click="
              () =>
                openDialog(
                  SIDEBAR_IDS.pdpEcommerceblockProductInfoAndDetailsSideSlide
                )
            "
          />
          <PdpEcommerceBlockProgressiveLabel
            v-if="productCare.items?.length"
            :label="$ts('pdp.productCare')"
            @click="
              () =>
                openDialog(SIDEBAR_IDS.pdpEcommerceBlockProductCareSideSlide)
            "
          />
          <PdpEcommerceBlockProgressiveLabel
            v-if="!!productFocus?.items?.length"
            :label="$ts('pdp.productFocus')"
            @click="
              () =>
                openDialog(SIDEBAR_IDS.pdpEcommerceBlockProductFocusSideSlide)
            "
          />
        </div>

        <div class="gap-md flex items-center">
          <button
            :data-testid="
              addedToWishlist ? 'added-to-wishlist' : 'not-in-wishlist'
            "
            :aria-label="
              !!wishlistProductCodes[productCode]
                ? $ts('aria-labels.pdp.removeFromWishlist')
                : $ts('aria-labels.pdp.addToWishlist')
            "
            @click="emit('update-wishlist')"
          >
            <DefaultIconsHeart
              width="24"
              height="24"
              :fill="addedToWishlist ? 'black' : 'none'"
              aria-hidden="true"
            />
          </button>
          <MoleculesShareButton
            :aria-label="$ts('sharePopup.shareProd')"
            :popup-title="$ts('sharePopup.shareProd')"
            :url="fullURL"
            sharing-icons-pdp
          />
        </div>
      </div>
    </div>

    <!-- PRDOUCT INFO SIDEBARS -->
    <PdpEcommerceBlockInfoAndDetailsSideSlide
      :product-details="productDetails"
    />

    <PdpEcommerceBlockProductCareSideSlide
      v-show="!!productCare.items?.length"
      :product-care="productCare"
    />
    <PdpEcommerceBlockProductFocusSideSlide
      v-show="!!productFocus?.items?.length"
      :items="productFocus?.items ?? []"
    />
  </div>
</template>
