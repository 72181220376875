<script setup lang="ts">
import type { PdpEcommerceBlockGallerySliderProps } from './PdpEcommerceBlockGallerySlider.props'
import { vIntersectionObserver } from '@vueuse/components'
import type { ProductGalleryImage } from '@design-system/components/UI/UIProductGallery.props'
import { Pagination } from 'swiper/modules'

const props = defineProps<PdpEcommerceBlockGallerySliderProps>()
defineEmits(['complete-the-look'])

const { ts } = useI18n()
const { isIos } = useDevice()
const stripMaxHeight = useCssVar('--marketing-strip-max-height')
const monogramMaxHeight = useCssVar('--monogram-strip-height')

const slideIndex = ref(0)
const rerenderKey = ref(0)

const ecommSliderEventBus = useEventBus<number>(
  'ecommerce-block-slider-event-bus'
)

const onIntersectImage: IntersectionObserverCallback = ([
  { isIntersecting, target },
]) => {
  if (isIntersecting) {
    const intersectingSlide = Number(target.getAttribute('data-image-index'))
    ecommSliderEventBus.emit(intersectingSlide)
  }
}

onMounted(() => {
  // window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  // window.removeEventListener('scroll', handleScroll)
})

const setSlideIndex = (index: number) => {
  slideIndex.value = index
  // Rerender the gallery inside the OrganismsModal to update the active slide
  // because the OrganismsModal is always mounted
  rerenderKey.value++
}

const togglePause = (videoId: string) => {
  const video = document.getElementById(videoId) as HTMLVideoElement | null
  if (video) {
    if (video.paused) {
      video.play()
    } else {
      video.pause()
    }
  }
}

const onIntersectVideo: IntersectionObserverCallback = ([
  { isIntersecting, target },
]) => {
  const videoTarget = target as HTMLVideoElement

  if (isIntersecting) {
    videoTarget.play()

    const intersectingSlide = Number(target.getAttribute('data-image-index'))
    ecommSliderEventBus.emit(intersectingSlide)
  } else {
    videoTarget.pause()
  }
}

// Mobile animation logic
const { y } = useWindowScroll()

const { breakpoint } = useProjectBreakpoint()

const isLarge = breakpoint.greaterOrEqual('lg')
const isMobile = breakpoint.isSmallerOrEqual('md')

const ZERO_INDEX_HEIGHT =
  removePx(monogramMaxHeight.value) + removePx(stripMaxHeight.value)

const isScrolled = computed(() => y.value > ZERO_INDEX_HEIGHT && !isLarge.value)

const gallery = computed(() => {
  // Tablet-Mobile viewport
  if (!isLarge.value) {
    return props.gallery.filter(item => item.type !== 'TResponsiveImage3D')
  }
  // Desktop viewport
  return props.gallery
})

// Filter TResponsiveImage for full screen gallery
const fullScreenGallery = computed(() =>
  gallery.value.filter(
    (item): item is ProductGalleryImage =>
      item.type === 'TResponsiveImage' || item.type === 'TResponsiveVideo'
  )
)

// check if the gallery has TResponsiveImage3D
const tResponsiveImage3DIndices = computed(() => {
  const indices: number[] = []
  gallery.value.forEach((item, index) => {
    if (item.type === 'TResponsiveImage3D') {
      indices.push(index)
    }
  })
  return indices
})

const getScaleRatio = (yVal: number) => {
  const ratio = (yVal - ZERO_INDEX_HEIGHT) / (ZERO_INDEX_HEIGHT * 1.5)
  return Math.min(Math.max(ratio, 0), 1)
}

// 0 is y.value === ZERO_INDEX_HEIGHT and 100 is y.value === ???, returns an integer
const scaleRatio = ref(getScaleRatio(y.value))
watch(y, (val, oldVal) => {
  if (Math.abs(getScaleRatio(val) - getScaleRatio(oldVal)) > 0.005) {
    scaleRatio.value = getScaleRatio(val)
  }
})

// when scaleRatio is 0 the height should be 100%, when scaleRatio is 1, the height should be 22% on mobile and 18% on tablet, returning an integer.
const getPercentage = computed(() => {
  const percentage = 100 - scaleRatio.value * (isMobile ? 78 : 82)
  return Math.floor(percentage) + '%'
})
const getPercentageLandscape = computed(() => {
  const percentage = 100 - scaleRatio.value * 90
  return Math.floor(percentage) + '%'
})

const getTransition = computed(() => {
  return getPercentage.value > '85%'
    ? 'width 0.05s ease-out'
    : 'width 0.5s ease-out'
})

const { openDialog } = useDialog()
const productGalleryId = 'product-gallery'
const scrollPosition = ref(0)

// const handleScroll = () => {
//   if (!isMobile) return
//   const currentScrollPosition = window.scrollY
//   const swiper = (document.querySelector('.swiperSlider') as any)?.swiper

//   if (currentScrollPosition < scrollPosition.value) {
//     if (swiper.activeIndex >= 4 && swiper.slides.length - 1 > 4) {
//       swiper.slideTo(swiper.slides.length - 4)
//     }
//   }
//   scrollPosition.value = window.scrollY
// }

// TODO: (tracked #2243) temporaneamente commentato, non funziona lo scroll, la gallery scatta
const showCompleteTheLook = false
</script>
<template>
  <!-- Full screen gallery -->
  <UIProductGallery
    :id="productGalleryId"
    :key="rerenderKey"
    :gallery="fullScreenGallery"
    :title="title"
    :current-index="slideIndex"
    :responsive-image3-d-indices="tResponsiveImage3DIndices"
  />
  <!-- Gallery -->
  <div class="gallery-slider relative">
    <Swiper
      :modules="[Pagination]"
      pagination
      slides-per-view="auto"
      :breakpoints="{
        1024: {
          enabled: false,
        },
      }"
      class="swiper bg-neutral-white swiperSlider -left-[--padding-x] w-screen lg:left-0 lg:w-full lg:bg-transparent"
      grab-cursor
    >
      <!-- :class="{
        'carousel-overflowed transition-opacity duration-200 [&>*>.image-wrapper]:h-[105px] [&>.swiper-pagination]:opacity-0':
          isScrolled,
      }" -->
      <SwiperSlide
        v-for="(media, index) in gallery"
        :key="`ecommerce-block-slide-${index}`"
        :class="{
          'scrolling-div border-stroke-card-default border': isScrolled,
          'border-r-transparent': index + 1 !== gallery.length && isScrolled,
        }"
        @click="setSlideIndex(index)"
      >
        <div
          :id="`ecommerce-block-slide-${index}`"
          class="relative flex h-full w-full items-center justify-center"
        >
          <!-- IF SIMPLE IMAGE -->
          <button
            v-if="media.type === 'TResponsiveImage'"
            class="image-wrapper custom-zoom-cursor-add h-auto w-full"
            :aria-label="`${title} ${$ts('accessibility.slide')} ${index}`"
            aria-haspopup="dialog"
            @click="openDialog(productGalleryId)"
          >
            <NuxtPicture
              v-intersection-observer="[onIntersectImage, { threshold: 0.5 }]"
              provider="cloudinary"
              :data-image-index="index"
              :src="media.data"
              :placeholder="[67, 85]"
              :alt="`${title} ${$ts('accessibility.slide')} ${index}`"
              class="aspect-pdp-image flex h-auto w-full items-center justify-center transition-transform duration-300 lg:h-[calc(100vh_-_var(--navbar-height))]"
              sizes="sm:120vw"
              quality="auto:best"
              :modifiers="{ aspectRatio: '4:5' }"
              fit="fill"
              :loading="index > 0 ? 'lazy' : undefined"
              :img-attrs="{
                class: 'lg:h-full lg:object-contain',
                fetchpriority: index === 0 ? 'high' : undefined,
              }"
            />
          </button>
          <!-- IF VIDEO -->
          <button
            v-if="media.type === 'TResponsiveVideo'"
            class="image-wrapper h-full w-full lg:!h-auto"
            :aria-label="title + ' slide: ' + index"
            aria-haspopup="dialog"
          >
            <video
              :id="`large_videoControl_${index}`"
              v-intersection-observer="[onIntersectVideo, { threshold: 0.5 }]"
              :muted="true"
              :loop="true"
              controlsList="nofullscreen nodownload"
              webkit-playsinline
              playsinline
              :data-image-index="index"
              :poster="posterVideo(media.data.cover)"
              class="aspect-pdp-image custom-zoom-cursor-add videoControl h-full w-full cursor-auto object-cover lg:h-[calc(100vh_-_var(--navbar-height))] lg:object-contain"
              @click="openDialog(productGalleryId)"
            >
              <source
                v-if="!isIos"
                :src="`${optimizeVideo(media.data.url, 'best', undefined, 'webm')}#t=0.001`"
                type="video/webm"
              />
              <source
                :src="`${optimizeVideo(media.data.url, 'best')}#t=0.001`"
                type="video/mp4"
              />
            </video>
            <button
              v-if="isScrolled && !isMobile"
              class="absolute right-0 top-0"
              :aria-label="$ts('accessibility.playPause')"
              @click="togglePause(`large_videoControl_${index}`)"
            >
              <DefaultIconsPlayFill class="h-6 w-6" aria-hidden="true" />
            </button>
          </button>

          <!-- IF 3D IMAGE -->
          <button
            v-if="media.type === 'TResponsiveImage3D'"
            class="image-wrapper lg:!h-auto"
            :aria-label="title + ' slide: ' + index"
          >
            <div
              v-if="!isScrolled"
              class="h-[45vh] w-screen lg:h-[calc(100vh_-_var(--navbar-height))] lg:w-[33vw]"
            >
              <PdpImage3dIframe :model="media.data.model" />
            </div>
            <NuxtPicture
              v-else
              v-intersection-observer="[onIntersectImage, { threshold: 0.5 }]"
              src="/images/sample-3d-logo.webp"
              sizes="sm:100vw lg:33vw"
              class="h-full w-full lg:h-[calc(100vh_-_var(--navbar-height))]"
              :alt="title"
            />
          </button>
        </div>
      </SwiperSlide>
    </Swiper>
    <h3
      v-if="showCompleteTheLook"
      class="text-book-8 md:text-book-7 bg-primitives-white border-primitives-grey-100 py-xs px-sm absolute bottom-8 right-2 z-10 cursor-pointer border uppercase lg:hidden"
      :class="{ hide: isScrolled }"
      @click="$emit('complete-the-look')"
    >
      {{ ts('pdp.productDetails.informationSection.completeTheLook') }}
    </h3>
  </div>
</template>
<style lang="scss" scoped>
.custom-zoom-cursor-add {
  cursor:
    url('@design-system/icons/AddWBkg.svg') 24 24,
    auto;
}

.swiper :deep(.swiper-pagination) {
  text-align: left;
  margin: 0 0 1.75rem 1rem;
}

.swiper :deep(.swiper-wrapper) {
  @screen lg {
    transform: none !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 0 !important;
  }
}

// .no-padding > div > .swiper-slide {
// padding-bottom: 0px !important;
//transition: padding 200ms ease-out;
//}
.swiper :deep(.swiper-slide) {
  background-color: transparent !important;
  // padding-bottom: 3rem;
  // animation: animate-me 1ms ease-in-out;
  // animation-timeline: scroll(block nearest);

  @screen lg {
    padding: 0 !important;
    height: calc(100vh - var(--navbar-height)) !important;
    width: 100% !important;
    display: flex !important;
    align-content: center !important;
  }
}

.swiper {
  background-color: #fbfbfb;
}

.swiper::after {
  content: '';
  border-bottom: 2px solid #000;
  width: 40px;
  display: block;
  margin: auto;
  margin-top: 1rem;
  border-radius: 10px;

  @screen lg {
    display: none;
  }
}

// @keyframes animate-me {
//   from {
//     width: 100%;
//   }
//   to {
//     width: 25%;
//   }
// }

// .scrolling-div {
//   width: v-bind(getPercentage);
//   will-change: width;
//   transition: v-bind(getTransition);
//   scroll-behavior: smooth;

//   @screen sm {
//     height: auto;

//     button {
//       height: 100%;

//       img {
//         height: 100%;
//       }
//     }
//   }
// }

// @media (orientation: landscape) {
//   .scrolling-div {
//     // width: v-bind(getPercentageLandscape);
//     // will-change: width;
//     // transition: v-bind(getTransition);
//     // scroll-behavior: smooth;
//   }
// }

/*
.product-height {
  height: calc(
    100dvh - var(--marketing-strip-height) - var(--navbar-height) - var(
        --monogram-strip-height
      ) - var(--logo-header-height) - 64px - 45px
  );
}
@screen lg {
  height: 100%;
}
*/

@keyframes hide {
  from {
    display: flex;
  }

  to {
    transform: translate3D(0, 0, 0);
    opacity: 0;
  }
}

.hide {
  animation: hide 200ms;
  display: none;
}

.zoom {
  border: 0;
  font-size: 20px;
  color: black;
  background: #ffffff;
  cursor: pointer;
  width: 30px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-out {
  border-bottom-left-radius: 10rem;
  border-top-left-radius: 10rem;
  box-shadow: -4px 1px 13px -9px rgb(0 0 0 / 75%);
  padding-left: 1.25rem;
}

.zoom-in {
  margin-right: 1rem;
  border-bottom-right-radius: 10rem;
  border-top-right-radius: 10rem;
  box-shadow: 2px 1px 13px -9px rgb(0 0 0 / 75%);
  padding-right: 1.25rem;
}
</style>
