<script setup lang="ts">
import type { PdpEcommerceBlockGalleryThumbnailsProps } from './PdpEcommerceBlockGalleryThumbnails.props'
import { A11y } from 'swiper/modules'

defineProps<PdpEcommerceBlockGalleryThumbnailsProps>()

const { isIos } = useDevice()

const scrollIntoViewWithOffset = (el: HTMLElement, offset: number) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  })
}

const headerHeight = useCssVar('--header-default-height', null, {
  observe: true,
})

// Go to `#ecommerce-block-slide-${index}`
const scrollIntoView = (index: number) => {
  const el = document.getElementById(`ecommerce-block-slide-${index}`)
  if (el) {
    scrollIntoViewWithOffset(el, removePx(headerHeight.value))
  }
}
</script>

<template>
  <Swiper
    slides-per-view="auto"
    class="carousel-overflowed"
    slide-to-clicked-slide
    :modules="[A11y]"
    grab-cursor
  >
    <SwiperSlide
      v-for="(media, index) in gallery"
      :key="`desktop-gallery-ecomm-block-${index}`"
      class="!w-fit [&:has(.active-slide)]:z-10"
    >
      <PdpEcommerceBlockGallerySlide v-slot="{ activeSlide }">
        <button
          :aria-label="
            $ts('accessibility.scrollToMedia', {
              n: index,
              total: gallery.length,
            })
          "
          class="h-full w-full"
          :class="{ 'cursor-auto': index === activeSlide }"
          @click="scrollIntoView(index)"
        >
          <UIThumbnail
            :is-last-slide="index === gallery.length - 1"
            :selected="index === activeSlide"
            :border-right="true"
            :class="{
              'active-slide': index === activeSlide,
            }"
            :is-video="media.type === 'TResponsiveVideo'"
          >
            <NuxtPicture
              v-if="media.type === 'TResponsiveImage'"
              :src="media.data"
              provider="cloudinary"
              sizes="68px"
              quality="auto:low"
              :modifiers="{ aspectRatio: '4:5' }"
              :img-attrs="{
                fetchpriority: 'high',
              }"
              fit="fill"
              class="h-[85px] w-[68px] object-contain"
              :alt="media.data"
            />
            <NuxtPicture
              v-else-if="media.type === 'TResponsiveImage3D'"
              src="/images/sample-3d-logo.webp"
              class="h-[85px] w-[68px] object-contain"
              sizes="68px"
              :modifiers="{ aspectRatio: '4:5' }"
              fit="fill"
            />
            <video
              v-if="media.type === 'TResponsiveVideo'"
              :id="`small_videoControl_${index}`"
              :muted="true"
              :loop="true"
              controlsList="nofullscreen nodownload"
              webkit-playsinline
              playsinline
              autoplay
              :poster="posterVideo(media.data.cover)"
              :data-image-index="index"
              class="h-[85px] w-[68px] object-contain"
            >
              <source
                v-if="!isIos"
                :src="optimizeVideo(media.data.url, 'best', undefined, 'webm')"
                type="video/webm"
              />
              <source
                :src="optimizeVideo(media.data.url, 'best')"
                type="video/mp4"
              />
            </video>
          </UIThumbnail>
        </button>
      </PdpEcommerceBlockGallerySlide>
    </SwiperSlide>
  </Swiper>
</template>

<style lang="scss" scoped>
.swiper :deep(.swiper-wrapper) {
  transition-duration: 0.01s !important;
  transition: all 0.4s ease-in-out 0.4s;
}
</style>
