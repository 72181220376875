import type { CommerceLayerClient, Sku } from '@commercelayer/sdk'
import type { ListResponse } from '@commercelayer/sdk'
import type { AlgoliaProductType } from '@integration-layer/modules/05.productServices/runtime/components/WrapperProductTile.props'
import type {
  extColor,
  ColorOption,
} from '@design-system/components/Pdp/PdpColorSelector.props'
import type { SizeData } from '@design-system/components/Pdp/PdpFitFinder.props'
import type { ProductCareItemList } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductInfo.props'
import type { PdpEcommerceBlockProductFocusSideSlideProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductFocusSideSlide.props'
import type { MainCategory } from '@integration-layer/components/Templates/TemplatesPdp.props'
import { getLangCountryMap } from '@integration-layer/integrationConfig'
import { countries } from '@design-system/configs/countries'

export type AlgoliaSize = AlgoliaProductType['size']
export type PDPMappedProductData = {
  title: string
  price: number
  priceCents?: number
  amountFloat: number
  currencyCode: string
  oldPrice?: number
  discount?: string
  tags?: string[]
  isRetailExclusive?: boolean
  selectedColor: string
  computedLists: {
    skuList: string[]
    sizeList: {
      sku: string
      size: string
      disabled: boolean
      lastInStock: boolean
      avEcom?: number | undefined
      sizePos?: string
    }[]
  }
  rawImages: string[]
  raw3DModels: string[]
  orderedGallery: TResponsiveGallery
  productPdf: string
  brand: string
  altColorList: ColorOption[]
  productDetails: {
    editoralDescription: string
    technicalDetails: string[]
    SkuCode: string
    fitDescription: string
  }
  sizes: AlgoliaSize
  SizeGridCode: string
  extColors: extColor[]
  colorHex?: string
  extSizes?: {
    label?: string
    SKU?: string
    Dims?: string
    avEcom?: number
    avRet?: number
    image?: string
  }[]
  hasExtSizes: boolean
  infoCommerce?: boolean
  mainCategory: MainCategory[]
  editorialDeliveryKeys?: string[]
  productCategoriesSlugs: string[]
  categories: {
    category1: string
    category2: string
    category4: string
  }
  productCare: ProductCareItemList
  productFocus: PdpEcommerceBlockProductFocusSideSlideProps | null
  upcs: { [size: string]: string }
  sizesData: SizeData[]
  productPath: string
  productPathWithoutLocale: string
  alternateUrls: {
    rel: string
    hreflang: string
    href: string
  }[]
  MFC: string
}
type AlternateNameClean = { name: string; lang: string }

export const useTemplatePdp = () => {
  const nuxtApp = useNuxtApp()
  const requestUrl = useRequestURL()
  const configs = useConfigs()
  const appConfig = useAppConfig()
  const algoliaIndex = appConfig.currentAlgoliaIndex
  const { $cl } = useNuxtApp()
  const { lineItems } = useCart()
  const { setCartEventsPayload } = useGACartEvents()
  const { getProductCareItemsByID } = useProductCare()
  const { pdpPath } = useRouteHelper()
  const langCountryMap = getLangCountryMap()

  const productDataState = useState<PDPMappedProductData | null>(
    'pdp-mapped-product-data',
    () => null
  )
  const productDataStateForGA = useState<{
    algoliaProduct: AlgoliaProductType
    commercelayerProduct: Sku[]
  } | null>('product-state-for-ga', () => null)

  // HELPER METHODS
  const isDisabled = (
    skus?: Sku[] | null,
    skuCode?: string,
    isAvailable?: boolean
  ) => {
    const sku = skus?.find(sku => sku.code === skuCode) ?? false
    return !sku || !isInStock(sku) || !isAvailable
  }

  const isLastInStock = (
    isTheLastOne: boolean,
    skus?: Sku[] | null,
    skuCode?: string
  ) => {
    const sku = skus?.find(sku => sku.code === skuCode) ?? false
    return !sku || isLastInStockFn(sku) || isTheLastOne
  }

  const getUpcs = (clPricesAndStocks: Sku[] | null) => {
    const upcs: { [size: string]: string } = {}
    if (clPricesAndStocks) {
      const clPricesAndStocksVal = clPricesAndStocks
      clPricesAndStocksVal.forEach(clPricesAndStock => {
        const productCode = clPricesAndStock.code
        const codeSplit = productCode.split('_') ?? []
        const getSize = codeSplit.filter((item, index) => index > 3)
        const size: string = getSize.join('_')
        upcs[size] = clPricesAndStock.reference as string
      })
    }
    return upcs
  }

  const getComputedLists = (
    sizes: AlgoliaProductType['size'],
    clPricesAndStocks: Sku[]
  ) => {
    const skuList: string[] = []
    const sizeList: {
      sku: string
      size: string
      disabled: boolean
      lastInStock: boolean
      avEcom?: number
      sizePos?: string
    }[] = []

    sizes?.forEach(size => {
      const itemInCart = lineItems.value.find(
        item => item.sku_code! === size.SKU
      )
      if (typeof size.SKU === 'string' && !skuList.includes(size.SKU)) {
        skuList.push(size.SKU)
      }
      if (
        typeof size.label === 'string' &&
        typeof size.SKU === 'string' &&
        !sizeList.some(item => item.sku === size.SKU)
      ) {
        sizeList.push({
          sku: size.SKU,
          size: size.label,
          sizePos: size.sizePos,
          disabled: isDisabled(
            clPricesAndStocks,
            size.SKU,
            itemInCart
              ? itemInCart.quantity < (itemInCart.item.inventory?.quantity || 0)
              : true
          ),
          lastInStock: isLastInStock(
            itemInCart
              ? itemInCart.item.inventory?.quantity - itemInCart?.quantity === 1
              : false,
            clPricesAndStocks,
            size.SKU
          ),
        })
      }
    })

    return {
      skuList,
      sizeList,
    }
  }

  const getAltColorList = async (params: {
    altColors: AlgoliaProductType['alternative-color']
    computedImages: string[]
    selectedColor: string
    colorHex: AlgoliaProductType['MacroColorEsa']
    isInStockSelectedColor: boolean
    extColors: extColor[]
    skuCode: string
    currentProductPath: string
  }) => {
    const {
      altColors,
      computedImages,
      selectedColor,
      colorHex,
      isInStockSelectedColor,
      extColors,
      skuCode,
      currentProductPath,
    } = params

    let mappedAltColorsList: ColorOption[] = []

    // if the product as altColors fetch the data for each colorSku and map it to the ColorOption type
    if (altColors) {
      mappedAltColorsList = (
        await Promise.all(
          altColors.map(async element => {
            const altProduct = await nuxtApp.runWithContext(
              async () =>
                await $fetch(
                  `/api/getProduct/${algoliaIndex}/${element.objectID}`
                ).catch(() => null)
            )
            if (!altProduct) return
            // keep assets, not images
            const altImage = getFirstMediaByPriority(checkMedia(altProduct))
            const altColor = altProduct?.ColorDesc

            const isInStockAltColor = altProduct.size.some(
              size => size.avEcom && size.avEcom > 0
            )

            return {
              thumbnailUrl: altImage?.split('/')?.slice(-1)?.toString() ?? '',
              color: altColor,
              sku: altProduct.objectID,
              isInStock: isInStockAltColor,
              path: pdpPath(altProduct),
            } as ColorOption
          })
        )
      ).filter((colorOpt: ColorOption | undefined) => !!colorOpt)

      mappedAltColorsList.unshift({
        thumbnailUrl: computedImages[0] ?? '',
        color: selectedColor,
        sku: skuCode,
        isInStock: isInStockSelectedColor,
        path: currentProductPath,
      })
    }

    // if the product has extColors (i.e. colors for beauty products, such as lipstick), fetch the data for each colorSku and map it to the ColorOption type
    if (extColors?.length > 0) {
      const mappedExtColors = (
        await Promise.all(
          extColors.map(async extElement => {
            // fetch algolia data to build the extColor product path
            const extColorProduct = await nuxtApp.runWithContext(
              async () =>
                await $fetch(
                  `/api/getProduct/${algoliaIndex}/${extElement.objectID}`
                ).catch(() => null)
            )
            if (!extColorProduct) return

            return {
              thumbnailUrl: '',
              hexColor: extElement.hexColor,
              color: extElement.label,
              sku: extElement.objectID,
              isInStock: isInStockSelectedColor,
              path: pdpPath(extColorProduct),
            } as ColorOption
          })
        )
      ).filter((colorOpt: ColorOption | undefined) => !!colorOpt)

      mappedAltColorsList = [
        {
          thumbnailUrl: '',
          hexColor: colorHex ? colorHex : '',
          color: selectedColor,
          sku: skuCode,
          isInStock: isInStockSelectedColor,
          path: currentProductPath,
        },
        ...mappedExtColors,
      ]
    }

    return mappedAltColorsList
  }

  const getSizesData = async (items: AlgoliaProductType['size']) => {
    if (!items || items.length === 0) return []
    const gridCodes = items.map(item => item?.sizeCode)

    const data = await $fetch<Record<string, SizeData>>(
      '/api/getSizesBySizeCode/getSizesByCode',
      {
        method: 'POST',
        body: { sizeCodes: gridCodes },
      }
    )
    const sizes = Object.values(data)
    return sizes as SizeData[]
  }

  // FETCH
  /** This function handles the server API fetch from Algolia.
   * @param product - The product SKU
   * @param options.checkath
   * @returns {Promise<AlgoliaProductType>}
   * */
  const fetchAlgoliaProduct = async (product: string) => {
    const algoliaProduct = await $fetch(
      `/api/getProduct/${algoliaIndex}/${product}`
    )

    if (!algoliaProduct) {
      throw createError({
        statusCode: 404,
        message: `[ERROR]: PRODUCT ${product} NOT FOUND`,
        statusMessage: `[ERROR]: PRODUCT ${product} NOT FOUND`,
      })
    }

    setCartEventsPayload({ algoliaObjects: [algoliaProduct] })

    return algoliaProduct as AlgoliaProductType
  }

  const fetchCLPricesAndStocks = async (skuList: string[]) => {
    if (skuList.length < 0)
      throw createError({
        statusCode: 404,
        message: `[ERROR] FETCH CL PRICES AND STOCK: NOT FOUND THESE SKUS: ${skuList.join(', ')}`,
        statusMessage: `[ERROR] FETCH CL PRICES AND STOCK: NOT FOUND THESE SKUS: ${skuList.join(', ')}`,
      })

    const clPricesAndStocks = skuList.length
      ? (
          await Promise.all(
            chunk(skuList, 24).map(skuCodesChunk =>
              $cl.skus.list({
                include: [
                  'prices',
                  'stock_items',
                  'stock_items.reserved_stock',
                ],
                filters: {
                  code_in: skuCodesChunk?.join() ?? '',
                },
                pageSize: 24,
              })
            )
          )
        ).flat()
      : []

    if (!clPricesAndStocks) {
      throw createError({
        statusCode: 404,
        statusMessage: `[ERROR] FETCH CL PRICES AND STOCK: NOT FOUND DATA FROM COMMERCELAYER OF THESE SKUS: ${skuList.join(', ')}`,
        message: `[ERROR] FETCH CL PRICES AND STOCK: NOT FOUND DATA FROM COMMERCELAYER OF THESE SKUS: ${skuList.join(', ')}`,
      })
    }
    return clPricesAndStocks
  }

  const mapAlgoliaProductData = async (algoliaProduct: AlgoliaProductType) => {
    const title = algoliaProduct.Name

    const tags = algoliaProduct.tags

    const selectedColor =
      algoliaProduct?.ColorDesc! ??
      algoliaProduct?.MacroColorDesc?.split('_')?.[1] ??
      ''

    const sizes = algoliaProduct.size.sort(
      (a, b) => parseInt(a.sizePos ?? '0') - parseInt(b.sizePos ?? '0')
    )

    const extColors = algoliaProduct['ext-color'] as extColor[]

    const colorHex = algoliaProduct.MacroColorEsa

    const extSizes = algoliaProduct['ext-size']

    const infoCommerce = algoliaProduct.infoCommerce
    const algoliaAssets = algoliaProduct.assets

    const rawImages = getRawImages(algoliaAssets)
    const raw3DModels = get3DModelURLs(algoliaAssets)
    const computedImages = getFormattedImages(algoliaAssets)
    const orderedGallery = getOrderedGallery(algoliaAssets)
    const productPdf =
      algoliaAssets?.find(asset => asset.type === 'pdf')?.URL ?? ''

    const brand = algoliaProduct.Brand ?? ''

    const altColors = algoliaProduct['alternative-color'] ?? []
    const alternateNames =
      algoliaProduct.NameURL?.filter(
        (nu): nu is AlternateNameClean => !!nu.lang && !!nu.name
      ) ?? []

    const langNameMap = new Map(alternateNames.map(an => [an.lang, an.name]))

    const alternateUrls = langCountryMap
      .filter(lc => langNameMap.has(lc.split('-')[0]))
      .map(lc => {
        const [lang] = lc.split('-')
        const thisLangPdpUrl = algoliaProduct.NameURL
          ? getPdpPath(algoliaProduct, lang)
          : ''
        return {
          rel: 'alternate',
          hreflang: lc,
          href: requestUrl.origin + '/' + lc + thisLangPdpUrl,
        }
      })

    const xDefault = alternateUrls?.find(au => au.hreflang === 'en-wx')
    if (xDefault) alternateUrls.push({ ...xDefault, hreflang: 'x-default' })

    // TODO: (tracked #1200) waiting for client feedback
    const mainCategory = algoliaProduct?.categoryPageId

    const editorialDeliveryKeys = algoliaProduct?.editorial

    const productCategories = algoliaProduct.categoryPageId

    const productPath = pdpPath(algoliaProduct)
    const productPathWithoutLocale = pdpPath(algoliaProduct, true, false)

    const isRetailExclusive = !!algoliaProduct.attributes?.RetailExclusive

    const productCategoriesSlugs = productCategories
      .map(categories => categories.slug)
      .filter(isNonNullable)

    const categories = extractCategories(algoliaProduct?.hierarchicalCategories)
    const isInStockSelectedColor = sizes.some(
      size => size.avEcom && size.avEcom > 0
    )

    // ALT COLORS
    // many times are empty
    const altColorList = await getAltColorList({
      altColors,
      computedImages,
      selectedColor,
      isInStockSelectedColor,
      extColors,
      colorHex,
      skuCode: algoliaProduct.objectID,
      currentProductPath: productPath,
    })

    const productDetails = {
      composition: algoliaProduct?.Composition!,
      editoralDescription: algoliaProduct?.LongDescription!,
      technicalDetails: [algoliaProduct?.ShortDescription!],
      SkuCode: algoliaProduct?.MFC!,
      fitDescription: '',
      // algoliaProduct?.modelFit ??
    }

    const productCare = getProductCareItemsByID(algoliaProduct.care ?? [])

    const { data: productFocus } = await nuxtApp.runWithContext(
      async () => await useProductFocus(algoliaProduct.focus ?? [])
    )

    const hasExtSizes = !!extSizes && extSizes?.length > 0

    const sizesData: SizeData[] = await getSizesData(sizes)

    const SizeGridCode = sizesData?.[0]?.SizeGrid_Code

    return {
      title,
      tags,
      isRetailExclusive,
      selectedColor,
      rawImages,
      raw3DModels,
      orderedGallery,
      productPdf,
      brand,
      altColorList,
      productDetails,
      sizes,
      extColors,
      colorHex,
      extSizes,
      hasExtSizes,
      mainCategory,
      editorialDeliveryKeys,
      productCare,
      productFocus: productFocus.value,
      productCategoriesSlugs,
      categories,
      sizesData,
      SizeGridCode,
      productPath,
      productPathWithoutLocale,
      alternateUrls,
      infoCommerce,
      MFC: algoliaProduct.MFC,
    }
  }

  const mapCLProductData = (
    clPricesAndStocks: Sku[],
    algoliaProduct: AlgoliaProductType
  ) => {
    const skuPrice = clPricesAndStocks?.[0]?.prices?.[0]
    const country = countries.find(
      country => country.code === configs.value.country
    )
    const currencyCode =
      skuPrice?.currency_code ?? (country && country.cl.currency) ?? 'EUR'

    const amountFloat = skuPrice?.amount_float ?? algoliaProduct.FullPrice

    const priceCents = skuPrice?.amount_cents

    const price: number = amountFloat

    const oldPrice =
      skuPrice?.compare_at_amount_float! > amountFloat
        ? skuPrice?.compare_at_amount_float!
        : undefined

    const discount =
      skuPrice?.compare_at_amount_float! > amountFloat
        ? percentageDiscount(skuPrice?.compare_at_amount_float!, amountFloat)
        : undefined

    // COMPUTED LISTS
    const sizes = algoliaProduct.size.sort(
      (a, b) => parseInt(a.sizePos ?? '0') - parseInt(b.sizePos ?? '0')
    )

    const computedLists = getComputedLists(sizes, clPricesAndStocks)

    const upcs = getUpcs(clPricesAndStocks)

    return {
      price,
      priceCents,
      amountFloat,
      currencyCode,
      oldPrice,
      discount,
      computedLists,
      upcs,
    }
  }

  const fetchAndMapProductData = async (
    code: string,
    options?: { updateState?: boolean }
  ) => {
    // on server, fetch algolia data and cache it
    // on client, use the cached data and fetch updated cl data, unless the data refresh is triggered by ext size change

    const algoliaP = await fetchAlgoliaProduct(code)

    if (!algoliaP) {
      console.error('Error fetching product data for extSize SKU:', code)
      return null
    }

    const productSizesSkuList = algoliaP.size
      ?.map(item => item.SKU)
      .filter(skuCode => skuCode !== undefined)

    const clStock = await fetchCLPricesAndStocks(productSizesSkuList)

    if (!clStock) {
      console.error('Error fetching product data for extSize SKU:', code)
      return null
    }

    if (options?.updateState) {
      productDataStateForGA.value = {
        algoliaProduct: algoliaP,
        commercelayerProduct: clStock,
      }
    }

    const algoliaDataMapped = await mapAlgoliaProductData(algoliaP)

    const clDataMapped = await mapCLProductData(clStock, algoliaP)

    const productData = {
      ...algoliaDataMapped,
      ...clDataMapped,
    }

    if (options?.updateState) productDataState.value = productData

    return productData
  }

  return {
    fetchAndMapProductData,
    productDataState, // THIS IS THE STATE YOU WANT TO IMPORT IF YOU NEED REACTIVE PRODUCT DATA
  }
}
